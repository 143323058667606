import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import ShipkooExpressPlusFeatured from 'images/featured-image/shipkoo-express-plus.png';
import ShipkooDeliverySteps from 'images/blog-images/shipkoo-delivery-steps.png';
import ShipkooShipmentTracking from 'images/blog-images/shipkoo-shipment-tracking.png';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query ShipkooExpressPlusImg {
      file(relativePath: { eq: "shipkoo-express-plus.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="shipkoo warehouse and inventory"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Shipkoo Express Plus - from China to the US | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-shipkoo-express-plus" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "Shipkoo Express Plus - from China to the US",
              content: intl.formatMessage({ id: "blog-post-meta-title-shipkoo-express-plus" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: ShipkooExpressPlusFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="shipkoo-express-plus-from-china-to-the-us"
        title="Shipkoo Express Plus - from China to the US"
        date="2020-07-20"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <p>
              Shipkoo Express Plus is a special line of method which is different from courier and express solutions. During the COVID-19 pandemic, since most of the express fulfillment centers are overwhelmed and are handling delays, our special lines can be faster than express (such as FedEx) because of its flexibility. Today, we are going to introduce one of our signature routes: China to the US Special Line.
            </p>

            <p className="no-bottom">
              <strong>
                Overview
              </strong>
            </p>

            <p>
              Our China-US line is a DDP service(Delivered Duty Paid) .We will help you to deal with customs clearance, duty issues and last-mile delivery .
            </p>

            <UnorderedList>
              <li>
                Who will need this  service? <br />
                Importers whose target market is in the US and Amazon sellers in the US
              </li>
              <li>
                How does our China-US Special Line work? <br />
                Stage 1: From the package being picked up to it arriving in the US <br />
                Stage 2: Customs clearance (Duty included)<br />
                Stage 3: Last-mile delivery: From the package being collected by our partnered carrier <br />
                (USPS) to it arriving at your end customer
              </li>
            </UnorderedList>

            <Image src={ShipkooDeliverySteps} className="" alt="shipkoo delivery steps"/>

            <UnorderedList>
              <li>
                How much will it be? <br />
                The rate is as low as 5.12 per item which includes every service in each of the stages mentioned above. Feel free to <Link to="/contact">contact</Link> our shipping specialist for your customized shipping solution.
              </li>
              <li>
                It will take 5 to 9 working days based on our history delivery records. What you need to do is provide your shipment ID or tracking number to your end customer. Then they could go to our <a href="https://app.shipkoo.com/tracker/" target="_blank" rel="noreferrer">website</a> to check the status of the package.
              </li>
            </UnorderedList>

            <Image src={ShipkooShipmentTracking} alt="shipkoo shipment tracking"/>

            <p className="no-bottom">
              <strong>
                What Shipkoo Can Do For You? (optional)
              </strong>
            </p>

            <UnorderedList className="no-top">
              <li>
                Pick up and collect goods from your suppliers
              </li>
              <li>
                Consolidate your goods to our own Fulfillment centers
              </li>
              <li>
                Re-packaging, weighing and measuring
              </li>
              <li>
                Prepare needed documents for shipping
              </li>
              <li>
                TMS module SaaS solution & OMS module SaaS solution
              </li>
            </UnorderedList>

            <p className="no-bottom">
              <strong>
                Requirements and Precautions
              </strong>
            </p>

            <UnorderedList className="no-top">
              <li>
                Cargo packaging requirements: carton must be clean and not broken, wooden box or and other packaging difficult to open won’t be accepted, do not accept irregular goods / non-carton goods
              </li>
              <li>
                P.O.BOX, Alaska, Hawaii, Puerto Rico, Guam, military address not received
              </li>
              <li>
                P.O.BOX, Alaska, Hawaii, Puerto Rico, Guam, military address not received
              </li>
              <li>
                Volume Weight: L*W*H(cm) / 6000, for general trade declarations export, please expect a  working day delay
              </li>
              <li>
                Imitation brand, counterfeit, dangerous goods and liquid will not be accepted. If your product is with a battery, please confirm with us since there will be some limitation for battery products
              </li>
              <li>
                You need to provide the corresponding power of attorney or certification report if the destination customs certificate that the goods are branded goods, or the certification issues of CE, Bluetooth, HDMI, FDA, FCC, Lacey Act, DOT and other certification , or goods belonging to the local anti-dumping, goods or non-compliance of the country’s import requirements of the goods
              </li>
            </UnorderedList>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;